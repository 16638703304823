@import "../vars";

.loginPage { 
  display: flex;
  justify-content: center;
  height: 100vh;
  max-height: 100vh;

}

.container {
  width: 100%;
  max-width: 550px;
  padding: 10px 50px;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(5px);
  border-radius: 15px;  
}

.elementWrapper {
  margin-bottom: 20px;
}

.elementWrapper2 {
  background: $blue-50;
  border: 2px solid $blue-10;
  padding: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  text-decoration: none;
  transition: background-color 100ms ease, color 100ms ease;
  display: inline-flex;
  gap: 10px;
  margin-bottom: 30px;
  &:hover {
      background: $blue-75;
  }
}

.subtitle {
  color: rgba(195,221,255,0.80);
  text-shadow: 0 0 15px rgba(195,221,255,0.25);
  margin-bottom: 30px;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .elementWrapper {
    margin-bottom: 0;
  }
}

.centered {
  text-align: center;
}

.card {

  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
  border-radius: 15px;
}

.blinker {
  height: 20px;
  width: 2px;
  background: #313131;
  animation: blinker .75s ease infinite;
  display: inline-block;

  @keyframes blinker {
    0%, 100% {
      opacity: 0;
    }
    25%, 50% {
      opacity: 1;
    }
  }
}

.text {
  flex: 1;
  p {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
    flex: 1;
  }
  svg  {
    margin-right: 10px;
    path {
      fill-opacity: 1;
    }
  }
}


@media (max-width: 480px) {
  .container {
    padding: 15px;
  }
  
  .text p {
    font-size: 13px;
  }
  
  .passwordHint {
    font-size: 11px;
  }
}

.inputLabel {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  p {
    font-size: 14px;
    color: #fff;    line-height: 25px;
    flex: 1;
  }
  svg  {
    margin-right: 10px;
    path {
      fill-opacity: 1;
    }

    
  }
}

.Jc3fE {
  height: 10px;
  width: 75px;
  background-color: #b2b2b23c;
  margin: 5px;
  border-radius: 10px;
  
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1000px) {
  .inputLabel {
    flex-direction: column;
  }
}



@import "../vars";

[data-state="active"] {
  background-color: white;
}

.elementSpacing {
  margin-bottom: 10px;
}

.topRow {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.bottomRow {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  width: 100%;

  .container {
    flex: 1;
  }
}

.formContainer {
  position: relative;
  max-width: 100%;
  margin: auto;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, .5);
  padding: 25px;
}

.form {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.premiumOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start; // Changed from center to flex-start
  padding-top: 50px; // Added padding to push content down from the very top
  z-index: 1000;
}

.premiumContent {
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  max-width: 80%; // Added to ensure content doesn't stretch too wide
  
  p {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.subscribeButton {
  padding: 10px 20px;
  background-color: $blue;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $blue-50;
  }
}

.blurred {
  filter: blur(3px);
  pointer-events: none;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="file"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid $blue-50;
  border-radius: 5px;
  background-color: $darker-bg;
  color: #fff;
  text-decoration: none;
  outline: none;
  resize: none;
}

small {
  display: block;
  margin-top: 5px;
  color: #e3e3e3;
}

label {
  color: #fff
}

.tagInput {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tagInput input {
  flex-grow: 1;
  margin-right: 10px;
}

button[type="button"] {
  padding: 10px;
  background-color: $blue;
  border: 1px dashed #fff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

button[type="button"]:hover {
  background-color: $blue-50;
}

.imagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  border: 1px solid $blue-50;
  background: $darker-bg;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.generateOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.generateText {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.generateSubText {
  font-size: 14px;
}

.placeholderImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  color: #ccc;
  margin: 10px;
}

.previewImage {
  max-width: 100%;
  max-height: 100%;
}

.voiceGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.voiceOption {
  display: flex;
  align-items: center;
}

.voiceOption input {
  margin-right: 10px;
}

.submitButton {
  width: 100%;
  padding: 15px;
  background-color: $blue;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: .25s ease-in-out;
}

.submitButton:hover {
  transform: scale(1.025);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 10px;
}

@media (max-width: 1000px) {
  .topRow {
    grid-template-columns: 1fr;
  }

  .bottomRow {
    flex-direction: column;
  }
}

.filterSection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;

  .filterButton {
    background-color: $blue-25;
    border: none;
    border-radius: 20px;
    color: #fff;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;

    &.active {
      background-color: #ff69b4;
    }
  }
}

.imageButton {
  position: relative;
  width: 150px;  // Adjust as needed
  height: 200px; // Adjust as needed
  background-size: cover;
  background-position: center;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  .buttonLabel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.35);
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
  }

  &.active {
    border: 3px solid $blue-50;
  }
}
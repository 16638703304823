.spinner {
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;

  svg {
    animation-name: logo-spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "../../assets/vars.scss";

.customDropdown {
  position: relative;
  width: 150px;
  user-select: none;

  &.emojiSelector {
    width: auto;

    .selectedOption {
      width: 50px;
      padding: 10px 5px;
      justify-content: center;
    }

    .optionsList {
      width: 150px;
      left: 0; // Align with the left edge of the emoji selector
      transform: none; // Remove the centering transform
    }
  }

  .selectedOption {
    background-color: #121212a0;
    color: #fff;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow {
      font-size: 10px;
      color: #f77cb3;
    }
  }

  .optionsList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #121212ed;
    border-radius: 5px;
    margin-top: 5px;
    padding: 0;
    list-style: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border: 2px solid $pink-25;

    .option {
      padding: 10px 15px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #2a2a2a;
      }

      &.selected {
        background-color: $pink-50;
      }

      .icon {
        margin-right: 10px;
        font-size: 16px;
      }
    }
  }
}

.chatDropdown {
  .optionsList {
    bottom: calc(100% + 5px);
    top: auto;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.createDropdown {
  position: relative;
  z-index: 1000 !important;
  
  .optionsList {
    position: absolute;
    top: -30px; // Move it 4px higher
    left: 100%; // Position to the right of the parent
    right: auto;
    margin: 0 0 0 5px; // Add a small gap to the right
    margin-left: 55px;
    transform: translateY(0);
    z-index: 1000 !important;
  }
}
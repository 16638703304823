@import "../../assets/vars.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  ///text-transform: uppercase;
  border-radius: 5px;
  font-weight: 900;
  color: black;
  background-color: #fff;
  border: none;
  height: 50px;
  width: 100%;
  padding: 0 15px;
  transition: background-color 100ms ease, color 100ms ease;
  cursor: pointer;
  font-size: 16px;
  transition: .25s ease-in-out;

  &:hover {
    transform: scale(1.025);
  }

  &:active {
    background-color: $pink-50;
  }

  &.thin {
    height: 24px;
    padding: 0 4px;
  }

  &.small {
    height: 24px;
    width: 24px;
    position: relative;
    padding: 0;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.danger {
    border: 2px solid $pink-10;
    background-color: $pink-5;

    svg {
      height: 12px;
      width: 12px;
      path {
        fill: white;
      }
    }

    &:hover {
      background-color: $red;
      color: white;
      box-shadow: 0 0 20px $red-50;
    }

    &:active {
      background-color: $red-50;
    }
  }

  &.dull {
    background-color: $pink-5 !important;
    color: $pink !important;

    &:hover {
      background-color: $pink-10 !important;
      color: white;
    }

    &:active {
      background-color: $pink-5 !important;
    }
  }

  &.inline {
    display: inline-block;
  }

  &.alt {
    background-color: $pink;
    color: black;

    &:hover {
      background-color: $pink-80;
      transform: scale(1.1);
    }

    &:active {
      background-color: $pink-50;
    }
  }

  &.outline {
    background-color: $pink-10;
    border: 2px solid $pink;
    color: $pink;
    box-shadow: 0 0 20px $pink-25;

    &:hover {
      background-color: $pink-25;
    }

    &:active {
      background-color: $pink-50;
    }

    &.danger {
      background-color: $red-10;
      border: 2px solid $red;
      color: $red;

      &:hover {
          background-color: $red-25;
      }

      &:active {
          background-color: $red-10;
      }
    }
  }

  &[disabled] {
    color: $pink-2;
    background-color: $pink-5;
    cursor: not-allowed;
  }
}

@import "../vars";

$breakpoint-mobile: 768px;

.continueButtonWrapper {
  display: flex;
  justify-content: left;
  margin-left: 37px;
}

.continueButton {
  background-color: transparent;
  color: white;
  border-bottom: 1px dashed $mf;
  font-size: 14px;
  cursor: pointer;
  transition: .2s ease-in-out;

  &:hover {
    transform: scale(1.025);
  }
}

.chatPageContainer {
  display: flex;
  justify-content: space-between !important;
  flex-direction: column;
  height: 100%;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  height: 85vh;
  min-height: 400px;
  background-color: $black-bg;

  @media (max-width: $breakpoint-mobile) {
    height: 85vh
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: $darker-bg;
  border-bottom: 2px solid $blue-25;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}

.characterImage {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 20px;

  @media (max-width: $breakpoint-mobile) {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
}

.characterInfo {
  flex-grow: 1;
  color: #fff;
  font-weight: bold;

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
  }

  h2 {
    margin: 2px;
    font-size: 18px;
    cursor: pointer;

    @media (max-width: $breakpoint-mobile) {
      font-size: 16px;
    }
  }

  p {
    margin: 2px;
    font-size: 12px;
    color: $mf;
    cursor: pointer;

    @media (max-width: $breakpoint-mobile) {
      font-size: 11px;
    }
  }
}

.disclaimerDropdown {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

.disclaimerToggle {
  cursor: pointer;
  color: #FFB800;
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 184, 0, 0.1);
  padding: 2px 12px;
  border-radius: 4px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile) {
    font-size: 11px;
  }
}

.disclaimerIcon {
  margin-right: 8px;
  font-size: 16px;
}

.disclaimerText {
  font-weight: 500;
  flex-grow: 1;
}

.expandIcon {
  font-size: 18px;
}

.disclaimerContent {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #1E232A;
  border: 1px solid rgba(255, 184, 0, 0.3);
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  color: #FFB800;
  z-index: 10;

  @media (max-width: $breakpoint-mobile) {
    font-size: 12px;
  }
}

.disclaimerDropdown:hover .disclaimerContent {
  display: block;
}

.disclaimerDropdown.open .disclaimerContent {
  max-height: 500px;
}

.chatBoxWrapper {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.chatBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.message {
  display: flex;
  margin-bottom: 20px;

  &.bot {
    justify-content: flex-start;
  }

  &.user {
    justify-content: flex-end;

    .messageContent {
      background-color: $blue;
    }
  }
}

.messageAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.messageContent {
  background-color: $green-50;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  max-width: 70%;

  @media (max-width: $breakpoint-mobile) {
    max-width: 85%;
    padding: 10px;
    font-size: 14px;
  }
}

.inputArea {
  padding: 10px;
  background-color: #1e1e1e;
  border-top: 1px solid #333;

  @media (max-width: $breakpoint-mobile) {
    padding: 5px;
  }
}

.inputRow {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 5px;
  transition: all 0.3s ease;
  margin-bottom: 10px;

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 5px;
  }

  &.expanded {
    .messageInput {
      height: 150px;
    }
  }
}

.topInputSection {
  display: flex;
  align-items: flex-start;
}

.inputWrapper {
  flex-grow: 1;
  position: relative;
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
}

.messageInput {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  resize: none;
  outline: none;
  transition: height 0.3s ease;
  padding: 8px;

  @media (max-width: $breakpoint-mobile) {
    font-size: 13px;
  }

  &::placeholder {
    color: #888;
  }
}

.expandButton {
  background: $darker-bg !important;
  border: none !important;
  font-size: 20px !important;
  color: #000;
  cursor: pointer;
  padding: 8px !important;
  align-self: flex-start;
  transition: .2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.bottomInputSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: stretch;
  }
}

.leftControls, .rightControls {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-mobile) {
    justify-content: space-between;
    margin-top: 5px;
  }
}

.leftControls > * {
  margin-right: 10px;
}

.rightControls > * {
  margin-left: 10px;
}

.voiceButton {
  background: $blue !important;
  border: 1px solid $green-50 !important;
  color: #fff;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background: $blue-50 !important;
  }

  @media (max-width: $breakpoint-mobile) {
    height: 40px;
    width: 60px;
    font-size: 16px;
  }
}

.sendButton {
  background-color: $blue;
  color: #fff;
  border-radius: 5px;
  width: 70px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    height: 40px;
    width: 60px;
    font-size: 16px;
  }
}

// Adjustments for CustomDropdown in chat context
.customDropdown.chatDropdown {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 5px;

  .selectedOption {
    background-color: transparent;
    padding: 0;
  }

  .optionsList {
    background-color: #3a3a3a;
    border: 1px solid #4a4a4a;
  }
}

// Adjustments for LabeledCheckbox in chat context
.nsfwToggle {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
@import "../vars";

.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 440px;
  padding: 22px 40px;
  //padding: 144px 50px; 
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(5px);
  border-radius: 15px;
}

.elementWrapper {
  margin-bottom: 20px;
}

.elementWrapper2 {
  background: $blue;
  border: 2px solid $blue;
  padding: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  text-decoration: none;
  transition: background-color 100ms ease, color 100ms ease;
  display: inline-flex;
  gap: 10px;
  margin-bottom: 30px;
  &:hover {
      background: $blue;
  }
}

.subtitle {
  color: $blue;
  text-shadow: 0 0 15px rgba(195,221,255,0.25);
  margin-bottom: 30px;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .elementWrapper {
    margin-bottom: 0;
  }
}

.centered {
  text-align: center;
}

.text {
  flex: 1;
  p {
    font-size: 14px;
    color: #313131;
    line-height: 10px;
    flex: 1;
  }
  svg  {
    margin-right: 10px;
    path {
      fill-opacity: 1;
    }
  }
}

.blinker {
  height: 15px;
  width: 1px;
  background: #fff;
  animation: blinker .75s ease infinite;
  display: inline-block;

  @keyframes blinker {
    0%, 100% {
      opacity: 0;
    }
    25%, 50% {
      opacity: 1;
    }
  }
}

.tooltipWrapper {
  position: relative;
  text-align: center;

  /*&:hover .tooltipMessage {
    opacity: 1;
    visibility: visible;
  }*/

  .tooltipIndicator {
    cursor: help;
    font-size: 16px;
    border: 2px solid $blue;
    background-color: $blue;
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    transition: border 100ms ease;
    padding: 2px; /* Increase padding to enlarge hover area */
  }

  .tooltipMessage {
    position: absolute;
    top: calc(-100% - 20px); /* Increase the gap between the indicator and tooltip */
    white-space: normal; 
    word-wrap: break-word; 
    right: -25px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: $blue;
    border: 2px solid $blue;
    box-shadow: 0 0 20px 1px $blue;
    padding: 5px;
    transition: opacity 100ms ease, visibility 100ms ease;
    opacity: 0;
    text-shadow: 0 0 15px $blue;
    max-width: 175px;
    z-index: 100; /* Ensure tooltip is above other elements */
    visibility: hidden;
  }

  .tooltipIndicator:hover + .tooltipMessage {
    opacity: 1;
    visibility: visible;
  }
}

@import "../vars";


.selectedPlan {
  border: 2px solid $blue;
  box-shadow: 0 0 10px rgba(0, 149, 255, 0.3);
}

.subscribeButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkoutButton {
  display: block;
  width: 100%;
  max-width: 350px;
  padding: 15px;
  background-color: $blue-80;
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover:not(:disabled) {
    background-color: $blue;
  }

  &:disabled {
    background-color: #4a4a4a;
    cursor: not-allowed;
  }
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  color: white;
  font-family: Arial, sans-serif;
}

.header {
  background: linear-gradient(90deg, #635BFF 0%, #776bc8 100%);
  padding: 20px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  .content {
    flex: 1;
    text-align: left;
    color: white;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    opacity: 0.8;
    max-width: 80%;
  }

  .imageGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 40%;

    .circleImage {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 2px solid white;
      margin: -3px;
      transition: .25s ease-in-out;

      &:hover {
        transform: scale(1.25);
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;

    .content {
      margin-bottom: 16px;
    }

    .imageGrid {
      max-width: 100%;
      justify-content: flex-start;

      .circleImage {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.ratingSection {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;

  .rating, .users {
    background-color: $black-bg;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;

    span {
      margin-right: 10px;
      font-size: 20px;
    }

    p {
      margin: 0;
    }
  }
}

.planSelector {
  text-align: center;
  margin-bottom: 30px;

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    opacity: 0.8;
    margin-bottom: 20px;
  }

  .billingToggle {
    display: inline-flex;
    background-color: $black-bg;
    border-radius: 20px;
    padding: 5px;

    button {
      background: none;
      border: none;
      color: white;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 15px;
      transition: background-color 0.3s;

      &.active {
        background-color: $blue;
      }

      .discount {
        background-color: #4CAF50;
        color: white;
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 14px;
        margin-left: 5px;
        font-weight: bolder;
      }
    }
  }
}

.plans {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 30px;

  .plan {
    background-color: $black-bg;
    border-radius: 10px;
    padding: 30px;
    width: 300px;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &.bestValue {
      //border: 2px solid $blue;

      .bestValueTag {
        position: absolute;
        top: -10px;
        right: 10px;
        background-color: $blue;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    h3 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .price {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 10px;

      span {
        font-size: 16px;
        opacity: 0.8;
      }
    }

    .description {
      font-size: 14px;
      opacity: 0.8;
      margin-bottom: 20px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 20px;

      li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        &::before {
          content: '✓';
          color: #4CAF50;
          margin-right: 10px;
        }
      }
    }

    .subscribeButton, .ccbillButton {
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .subscribeButton {
      background-color: $blue;
      color: white;
      transition: .25s ease-in;
    }

    .subscribeButton:hover {
      transform: scale(1.05);
      background-color: $pink-80;
    }

    .ccbillButton {
      background-color: transparent;
      border: 1px solid #4e54c8;
      color: #4e54c8;
    }
  }
}

.securityInfo {
  text-align: center;
  margin-bottom: 30px;

  p {
    display: inline-block;
    margin: 0 10px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid $blue-25
  }
}

.communityFeedback {
  margin-bottom: 30px;

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .feedbackGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  .feedbackItem {
    //background: rgba(255, 255, 255, 0.1);

    background: $black-bg;
    border: 1px solid $blue-25;
    padding: 20px;
    border-radius: 10px;

    p {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .userInfo {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
      }

      span {
        font-size: 12px;
      }
    }

    .rating {
      font-size: 14px;
    }
  }
}

.membershipPerks {
  margin-bottom: 30px;

  h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-size: 16px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      &::before {
        content: '✓';
        color: #4CAF50;
        margin-right: 10px;
      }
    }
  }
}

.faqSection {
  margin-bottom: 30px;

  h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .faqItem {
    margin-bottom: 10px;

    button {
      width: 100%;
      text-align: left;
      background-color: $black-bg;
      border: 1px solid $blue-10;
      padding: 15px;
      color: white;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;

      &:hover {
        background-color: $blue-10;
      }
    }

    p {
      background-color: $blue-10;
      border: 1px solid $blue-50;
      padding: 15px;
      margin-top: 5px;
      border-radius: 5px;
    }
  }
}

.footer {
  text-align: center;
  font-size: 14px;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .ratingSection {
    flex-direction: column;
    align-items: center;
  }

  .plans {
    flex-direction: column;
    align-items: center;

    .plan {
      width: 100%;
      max-width: 300px;
    }
  }

  .communityFeedback {
    .feedbackGrid {
      grid-template-columns: 1fr;
    }
  }

  .securityInfo {
  
    p {
      margin: 5px 10px;
    }
  }
}
@import "../../assets/vars.scss";

.labeledCheckbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff38;
  text-shadow: 0 0 15px $pink-25;
  transition: color 100ms ease;

  .checkbox {
    height: 24px;
    width: 24px;
    border: 2px solid $pink-50;
    background-color: $pink-5;
    position: relative;
    transition: border 100ms ease;

    &::after {
      content: "";
      display: block;
      height: 15px;
      width: 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $pink;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 200ms ease;
    }
  }

  &.checked {
    color: $pink;
    .checkbox {
      border: 2px solid $pink-5;
      background-color: $pink-2;

      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }
}

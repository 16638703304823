@import "../../assets/vars.scss";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #000;
  background-size: cover;
  background-position: center;
  border: 2px solid $pink-50;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  height: 50vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2); // Darkens the image
    backdrop-filter: blur(.5px); // Adds blur effect
  }
}

.modalContentInner {
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25); // Darkens the image
  border-radius: 4px;
  backdrop-filter: blur(5px); // Adds blur effect
  padding: 15px;
}

.modalTitle {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  text-shadow: 0 0 25px $pink-2;
}

.modalText {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: bold;
}

.modalActions {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.button {
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 100ms ease;
}

.cancelButton {
  background-color: $pink-25;
  color: #fff;
  border: 1px solid $pink-50;

  &:hover {
    background-color: $pink-50;
  }
}

.confirmButton {
  background-color: $pink;
  color: #000;

  &:hover {
    background-color: darken($pink, 10%);
  }
}
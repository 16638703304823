@import "../vars";

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  margin-bottom: 25px;
  position: relative;

  .content {
    width: 100%;
    padding: 20px;
    
    h2 {
      font-size: 38px;
      line-height: 1.2;
      color: #fff;
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 1.4;
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.createButton {
  background-color: $blue;
  color: #000000;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.aboutSection {
  color: #fff;
  padding: 20px;
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 15px;
  }
  
  a {
    color: $blue;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .header {
    height: auto;
    
    .content {
      h2 {
        font-size: 28px;
      }

      p {
        font-size: 14px;
      }
    }
  }
  
  .createButton {
    font-size: 12px;
    padding: 6px 12px;
  }
}
@import "vars";

body {
  //font-family: 'Merriweather', serif;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  text-shadow: 0 0 15px $blue-25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  //background-image: radial-gradient(circle at 50% 50%, #151A1F 0%, #090B0D 49%);
  user-select: none;
  width: 100vw;
  height: 100vh;
}

input {
  outline: none;
}

.Toastify__toast {
  padding: 15px !important;
  border: 2px solid $pink-10 !important;
  border-radius: 0 !important;
  // font-family: 'Merriweather', serif !important;
  font-family: 'Roboto Mono', monospace;
  font-size: 16px !important;
  background-color: #FAF9F6 !important;
  backdrop-filter: blur(20px) !important;
  font-weight: bold !important;

  .Toastify__close-button {
    justify-self: center !important;
    align-self: center !important;
  }

  .Toastify__toast-icon {
    width: 40px;
    svg {
      height: 40px;
      width: 40px;
      //margin-right: 10px;
    }
  }

  &--success {
    border: 2px solid $green !important;
    background-color: $green-10 !important;
    color: $green !important;

    svg {
      fill: $green !important;
    }
  }

  &--info {
    border: 2px solid $pink-25 !important;
    background-color: $darker-bg !important;
    color: #fff !important;

    svg {
      fill: $pink-50 !important;
    }

    .Toastify__close-button {
      justify-self: center !important;
      align-self: center !important;
      background-color: #fff;

      svg {
        fill: #000 !important
      }
    }
  }

    &--error {
      border: 2px solid $red !important;
      background-color: $red-10 !important;
      color: $red !important;

      svg {
        fill: $red !important;
      }
    }
}
$blue: #C3DDFF;
$blue-5: #C3DDFF0D;
$blue-10: #C3DDFF1A;
$blue-15: #C3DDFF26;
$blue-20: #C3DDFF33;
$blue-25: #C3DDFF40;
$blue-50: #C3DDFF80;
$blue-60: #C3DDFF99;
$blue-75: #C3DDFFBF;
$blue-80: #C3DDFFCC;
$pink: #FF1D8E;
$pink-2: #f77cb3;
$pink-5: #DD8DEE0D;
$pink-8: #dc8dee35;
$pink-10: #DD8DEE1A;
$pink-25: #DD8DEE40;
$pink-50: #DD8DEE80;
$pink-55: #dc8deec5;
$pink-80: #fdc1dc;
$red: #FF3737;
$red-10: #FF37371A;
$red-25: #FF373740;
$red-50: #FF373780;
$red-80: #FF3737CC;
$off-white: #F5FFF5;
$mf: #f5fff5d2;
$green: #89D489;
$green-5: #89D4890D;
$green-10: #89D4891A;
$green-50: #89D48980;
$black-bg: #1212126c;
$darker-bg: #121212a0
@import "../../assets/vars";

.mobile-only {
  display: none;
}

.desktop-only {
  display: flex;
}

.row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  width: 100%;
}

.hoverScaleButton {
  background-color: $blue-25;
  border: 2px solid $blue-50;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  transition: 0.2s ease-in-out;

  &:hover {
    transform: scale(1.025);
    background-color: $blue-50;
  }
}

.nav {
  width: 250px;
  background-color: $black-bg;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .menuButton {
    display: none;
  }

  .logo {
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    margin-top: 30px;
    margin-bottom: 50px;
    img {
      width: 59px;
      height: auto;
    }

    span {
      font-size: 20px;
      color: $blue;
    }
  }

  ul {
    li {
      a {
        color: #fff;
        text-decoration: none;
        display: flex;
        height: 40px;
        padding: 0 20px;
        margin: 10px;
        align-items: center;
        justify-content: flex-start;
        transition: background-color 100ms ease, color 100ms ease;
        gap: 14px;
        font-size: 13px;

        svg path {
          transition: fill 100ms ease, fill-opacity 100ms ease;
        }

        &:hover {
          color: $blue;
          background-color: $blue-5;
          svg path {
            fill: $blue;
            fill-opacity: 1;
          }
        }

        &.active {
          color: $blue;
          border-right: 2px solid $blue;
          background-color: $blue-5;
          svg path {
            fill: $blue;
            fill-opacity: 1;
          }
        }

        &.activeCategory {
          color: #fff;
          border-right: 1px solid #fff;
          border-bottom: 1px solid #fff;
          border-top: transparent;
          border-left: transparent;
          background-color: transparent;
          svg path {
            fill: $pink-2;
            fill-opacity: 1;
          }
        }
      }
    }
  }

  .userArea {
    background-color: $blue-5;
    .walletInfo {
      padding: 20px;

      .walletConnection {
        border: 2px solid $red;
        padding: 12px;
        margin-bottom: 10px;
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        background-color: $red-10;
        align-items: center;

        .walletIndicator {
          height: 11px;
          width: 11px;
          border: 2px solid $red;
          transform: rotate(45deg);
          background-color: $red-50;
        }
      }
    }

    .user {
      padding: 20px;
      background-color: $pink-5;
      color: #fff;
      font-weight: bold;

      .userButton {
        background: $pink-5;
        border: 2px solid $pink-10;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 12px;
        cursor: pointer;
        transition: background-color 100ms ease, border 100ms ease;

        .info {
          text-align: left;

          .userEmail {
            color: #fff;

            display: block;
            font-size: 12px;
          }

          .userSignIn {
            color: $pink-2;
            display: block;
            font-size: 10px;
          }
        }

        svg path {
          transition: fill 100ms ease, fill-opacity 100ms ease;
        }

        &:hover {
          background-color: $pink-10;
          border: 2px solid $pink;

          svg path {
            fill: $pink;
            fill-opacity: 1;
          }
        }
      }
    }
  }
}

.blurred {
  filter: blur(4px);
  position: relative;
}

.lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Adjust size, color, etc., as needed */
}

.scrollable {
  max-height: calc(100vh);
  overflow-y: auto;
}

.fixedBottom {
  position: absolute;
  bottom: 0;
  background-color: #fdc1dc;
}

@media (max-width: 1000px) {
  .mobile-only {
    display:inline;
  }

  .desktop-only {
    display: none;
  }

  .scrollable {
    max-height: calc(100vh - 10px); 
    overflow-y: auto;
  }

  .nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(9, 11, 13, 0.89);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.25);
    width: 100%;
    transform: translateX(-100%);
    transition: transform 200ms ease;

    &.navOpen {
      transform: translateX(0);
    }


    .menuButton {
      display: inline-flex;
      height: 50px;
      width: 50px;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 20px;
      left: 20px;
      background-color: $pink-50;
      border: 2px solid $pink-50;
      z-index: 99;
    }

    .logo {
      align-items: center;
      justify-content: center;
      display: flex;
      margin-bottom: 10px;
    }

    ul {
      padding: 20px;
      li {
        a {
          border: 2px solid $pink-10;
          margin-bottom: 10px;
          background-color: $pink-5;

          &.active {
            border: 2px solid $pink;
          }
        }
      }
    }
  }
}

@import "../vars";

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: white;
  font-family: Arial, sans-serif;
}

.header {
  background: $black-bg;
  border: 1px solid $blue-80;
  padding: 40px 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;

  h1 {
    font-size: 32px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    opacity: 0.8;
  }
}

.membershipDetails {
  background-color: #2a2d3e;
  border: 1px solid $blue-80;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.actions {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  flex-wrap: wrap;

  .actionButton, .cancelButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .actionButton {
    background-color: #4e54c8;
    color: white;
  }

  .cancelButton {
    background-color: #ff4d4d;
    color: white;
  }
}

.billingHistory {
  margin-bottom: 30px;

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .billingTable {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #3a3d4e;
    }

    th {
      background-color: #2a2d3e;
    }

    tr:nth-child(even) {
      background-color: #2a2d3e;
    }
  }
}

.faq {
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  details {
    margin-bottom: 15px;
    background-color: #2a2d3e;
    border-radius: 5px;
    padding: 10px;

    summary {
      cursor: pointer;
      padding: 10px;
      font-weight: bold;
    }

    p {
      padding: 10px;
      font-size: 14px;
    }
  }
}

.tabContainer {
  background-color: $black-bg;
  border: 1px solid $blue-80;
  border-radius: 8px;
  overflow: hidden;
}

.tabs {
  display: flex;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.tabButton {
  background: none;
  border: none;
  color: #ffffff;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 16px;
  opacity: 0.7;

  &.active {
    opacity: 1;
    background-color: $blue-25;
    color: #fff;
    font-weight: bold;
  }
}

.tabContent {
  padding: 20px;
}

.planTab, .accountTab, .securityTab {
  color: #ffffff;

  h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: normal;
  }
}

.planTab {
  .currentPlan {
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;

    li {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      color: #a0a0a0;

      &::before {
        content: "✓";
        color: #4e54c8;
        margin-right: 10px;
      }
    }
  }

  .usage, .bonus {
    margin-top: 20px;

    h3 {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: normal;
    }

    p {
      color: #a0a0a0;
      margin-bottom: 5px;
    }
  }
}

.accountTab {
  .formGroup {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 5px;
      color: #fff;
      font-weight: bold;
    }

    input, textarea {
      width: 100%;
      padding: 10px;
      background-color: $darker-bg;
      border: 1px solid $blue-25;
      border-radius: 4px;
      color: #fff;
    }

    textarea {
      height: 100px;
      resize: vertical;
    }
  }
}

.securityTab {
  .passwordSection {
    display: flex;
    justify-content:left;
    flex-direction: column;
    margin-bottom: 20px;

    p {
      margin: 0;
    }
  }

  .activeDevices {
    margin-bottom: 30px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        background-color: $darker-bg;
        border: 1px dashed $mf;
        padding: 15px;
        border-radius: 4px;

        p {
          margin: 5px 0;
          color: #a0a0a0;
          font-size: 14px;
        }
      }
    }
  }

  .dangerZone {
    border-top: 1px solid #3a3d4e;
    padding-top: 20px;

    h3 {
      color: #ff4d4d;
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: normal;
    }

    p {
      color: #a0a0a0;
      margin-top: 10px;
      font-size: 14px;
    }
  }
}

.upgradeButton, .getCoinsButton, .saveButton, .deleteAccountButton {
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.upgradeButton {
  background-color: $blue;
  color: #ffffff;
  border: none;
}

.getCoinsButton {
  background-color: #2a2d3e;
  color: #ffffff;
  border: none;
}

.saveButton {
  background-color: $blue-25;
  color: #ffffff;
  border: none;
  transition: .2s ease-in-out;
}

.saveButton:hover {
  transform: scale(1.05);
}

.updatePasswordButton {
  background: none;
  border: none;
  color: #4e54c8;
  cursor: pointer;
  font-size: 14px;
}

.deleteAccountButton {
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
}

.profilePictureSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profilePicturePreview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  background-color: $darker-bg;
  border: 1px solid $blue-25;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profilePictureUpload {
  .uploadButton {
    display: inline-block;
    padding: 10px 15px;
    background-color: $blue-50;
    border:  transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: .2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }
}

@media (max-width: 768px) {
  .actions {
    flex-direction: column;
  }

  .billingHistory {
    overflow-x: auto;
  }
}

.passwordSection {
  margin-bottom: 20px;
  
  h3 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .formGroup {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
      max-width: 225px;
      background-color: $darker-bg;
      padding: 8px;
      border: 1px solid $blue-80;
      border-radius: 4px;
      color: #fff;
    }
  }

  .updatePasswordButton {
    padding: 10px 15px;
    background-color: $blue-25;
    color: white;
    border: none;
    border-radius: 4px;
    max-width: 225px;
    cursor: pointer;
    transition: .25s ease-in-out;
    font-weight: bold;

    &:hover {
      transform: scale(1.05);
      background-color: $blue-25;
    }
  }
}

.planTab {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

.currentPlanInfo {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.daysRemaining {
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 30px;
}

.circularProgress {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circleBackground,
.circleProgress {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
}

.circleBackground {
  stroke: #3a3a3a;
}

.circleProgress {
  stroke: $blue-25;
  transition: stroke-dasharray 0.3s ease;
}

.daysText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.days {
  font-size: 24px;
  font-weight: bold;
  color: $blue-25;
  display: block;
}

.planDetails {
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .paymentInfo {
    font-size: 14px;
    color: #fff;
    margin-bottom: 5px;
    background-color: $blue-25;
    padding: 6px;
    border-radius: 4px;
  }
}

.otherPlansTitle {
  font-size: 24px;
  margin-bottom: 20px;
}


.planComparison {
  background-color:transparent;
  border-radius: 8px;
  padding: 20px;
  color: #e0e0e0;
}

.billingToggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  button {
    background-color: transparent;
    border: 1px solid #4a4a4a;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.active {
      background-color: $blue-25;
    }
  }
}

.planCards {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.planCard {
  flex: 1;
  background-color: $darker-bg;
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover:not(.currentPlan) {
    border-color: #6a6a6a;
  }

  &.currentPlan {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.selectedPlan {
    border-color: $blue-25;
    box-shadow: 0 0 10px rgba(0, 98, 255, 0.3);
  }

  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 10px;
    }
  }
}

.checkoutButton {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: $blue-80;
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover:not(:disabled) {
    background-color: $blue-25;
  }

  &:disabled {
    background-color: #4a4a4a;
    cursor: not-allowed;
  }
}


@media (max-width: 768px) {
  .currentPlanInfo {
    flex-direction: column;
    align-items: center;
  }

  .daysRemaining {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .planComparison {
    flex-direction: column;
  }

  .planCard {
    width: 100%;
  }
}

// Mobile responsiveness
@media (max-width: 768px) {
  .planComparison {
    padding: 15px;
  }

  .billingToggle {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      margin-bottom: 10px;

      &:first-child {
        border-radius: 4px;
      }

      &:last-child {
        border-radius: 4px;
      }
    }
  }

  .planCards {
    flex-direction: column;
    gap: 15px;
  }

  .planCard {
    width: 100%;

    h3 {
      font-size: 20px;
    }

    .price {
      font-size: 18px;
    }

    ul {
      li {
        font-size: 14px;
      }
    }
  }

  .checkoutButton {
    font-size: 16px;
    padding: 12px;
  }
}

.cancelButton {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #ff7875;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: $blue-25;
  padding: 20px;
  border-radius: 5px;
  text-align: center;

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }
}

.modalButtons {
  display: flex;
  justify-content: space-around;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.keepButton {
  background-color: $blue-25;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: bold;


  &:hover {
    background-color: $blue-80;
  }
}